import widgetly from 'widgetly/lib'
import EventEmitter from 'events'
import * as widgets from 'widgets'

EventEmitter.defaultMaxListeners = Infinity

export default function createMediator() {
  const mediator = widgetly(
    {
      prefix: process.env.WIDGETLY_PREFIX
    },
    {
      broadcast(name, params = {}) {
        this.emit(name, params)
      }
    }
  )

  Object.entries(widgets).map(([name, defineWidget]) =>
    defineWidget(mediator, name)
  )

  return mediator
}
