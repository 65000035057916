// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Ej1s3 {\n  height: 550px;\n}\n\n.xpBJO {\n  height: 350px;\n  margin: 20px 0;\n}\n\n.X0D3X {\n  height: 350px;\n}\n\n.FsQNG {\n  text-align: center;\n  border-radius: 1px;\n}\n\n._NKgA {\n  height: 100%;\n  position: relative;\n  display: inline-block;\n}\n\n.eNYo8 {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  width: 24px;\n  height: 24px;\n  margin-left: -12px;\n  margin-top: -6px;\n}\n", "",{"version":3,"sources":["webpack://./src/spinner/index.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,cAAc;AAChB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;AAClB","sourcesContent":[".Universal {\n  height: 550px;\n}\n\n.Poll {\n  height: 350px;\n  margin: 20px 0;\n}\n\n.Petition {\n  height: 350px;\n}\n\n.Spinner {\n  text-align: center;\n  border-radius: 1px;\n}\n\n.Spinner__container {\n  height: 100%;\n  position: relative;\n  display: inline-block;\n}\n\n.Spinner__circle {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  width: 24px;\n  height: 24px;\n  margin-left: -12px;\n  margin-top: -6px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Universal": "Ej1s3",
	"Poll": "xpBJO",
	"Petition": "X0D3X",
	"Spinner": "FsQNG",
	"Spinner__container": "_NKgA",
	"Spinner__circle": "eNYo8"
};
export default ___CSS_LOADER_EXPORT___;
