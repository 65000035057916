import EmbedLayout from 'widgetly/lib/layouts/EmbedLayout'
import createSpinner from 'spinner'
import {loadAndCreateTheme} from 'lib/theming'

const RUID_KEY = 'rquid'
const ONE_YEAR = 365 * 24 * 60 * 60 * 1000

const DEFAULT_PARAMS = {
  mode: 'default', // для Universal default/banner
  theme: 'default',
  color: '#315efb'
}

function getPseudoRuid() {
  const [pseudoRuid = null] = document.cookie
    .split(';')
    .filter((pair) => pair.trim().indexOf(`${RUID_KEY}=`) === 0)
    .map((pair) => pair.split('=').slice(1).join('='))

  return pseudoRuid
}

function prepareParams(originalParams) {
  return {
    pageUrl: window.location.href,
    pseudoRuid: getPseudoRuid(),
    ...DEFAULT_PARAMS,
    ...originalParams
  }
}

const {NODE_ENV, CDN_ORIGIN, CDN_PREFIX, VERSION} = process.env

const IFRAME_URL =
  NODE_ENV === 'development'
    ? `${CDN_ORIGIN}${CDN_PREFIX}`
    : `${CDN_ORIGIN}${CDN_PREFIX}/${VERSION}`

/**
 *  Метод инициализации виджета
 * @param {object} sdk
 * @param {string} name - есть src/widgets/{тип виджета}
 */
export default (sdk, name) => {
  sdk.defineWidget(
    {
      name,
      initialize: async function () {
        const {height, minHeight, maxHeight} = this.container.element.style
        const layoutStyle = {}
        if (height) layoutStyle.height = height
        if (minHeight) layoutStyle.minHeight = minHeight
        if (maxHeight) layoutStyle.maxHeight = maxHeight

        this.startTime = Date.now()
        this.properties.widgetName = name
        this.params = prepareParams({...this.params, layoutStyle})

        const iframeUrl = [
          `${IFRAME_URL}`,
          `/widget-${name.toLowerCase()}.html#widgetParams=`,
          encodeURIComponent(JSON.stringify(this.params))
        ].join('')

        this.theme = await loadAndCreateTheme({
          theme: this.params.theme,
          colorFromEmbedCode: this.params.color,
          themeMode: this.params.themeMode
        })

        this.layout = new EmbedLayout({
          spinner: createSpinner({
            type: name,
            color: this.theme.colors.primary,
            borderRadius: this.theme.borderRadius
          })
        })

        this.layout.show()
        this.layout.showLoading()
        this.iframe = this.createIFrame(iframeUrl)
        this.iframe.element.style.borderRadius = `${this.theme.borderRadius}px`
        this.iframe.element.style.transition = 'height 0.2s ease'
        this.container.element.style.borderRadius = `${this.theme.borderRadius}px`
        this.layout.addToDOM(this.container)
        this.layout.setContent(this.iframe)

        try {
          await this.iframe.initialize()

          this.iframe.consumer.addChangeSizeListener((size) => {
            this.emit('changeSize', size)
          })

          if (this.theme.errorCode) {
            this.iframe.consumer.trackLoadingError({
              widgetName: name,
              theme: this.params.theme,
              errorCode: this.theme.errorCode
            })
          }

          this.layout.hideLoading()
          this.iframe.resizer.resize()
          this.iframe.consumer.getSize().then((size) => {
            this.emit('changeSize', size)
          })
          this.emit('widgetShown')
        } catch (e) {
          this.destroy()
          throw e
        }
      }
    },
    {
      // Обогащение виджета

      getContainerWidth() {
        return this.container.element.offsetWidth
      },

      getWidgetTheme() {
        return this.theme
      },

      getStartTime() {
        return this.startTime
      },

      setParentUrl(url) {
        if (window.location.origin === process.env.CDN_ORIGIN) {
          window.open(url, '_blank')
        } else {
          window.location.href = url
        }
      },

      setPseudoRuid(ruid) {
        const {hostname} = window.location
        const domain = hostname.split('.').slice(-2).join('.')
        const expires = new Date()

        expires.setTime(expires.getTime() + ONE_YEAR)
        document.cookie = `${RUID_KEY}=${ruid}; domain=.${domain}; path=/; expires=${expires.toUTCString()}`
      }
    }
  )
}
