import {loadTheme, loadFonts} from '@rambler-id/customization'
import colors from 'rambler-ui/theme/colors'
import createRuiTheme from 'rambler-ui/theme/create-theme'
import {darken, lighten} from 'rambler-ui/utils/colors'
import deepmerge from 'deepmerge'
import defaultUi from 'constants/ui'

export async function loadAndApplyFonts(fontFamily, fontSheets) {
  const baseFamily = fontFamily

  document.body.style.fontFamily = baseFamily.split(/,/).slice(-1)
  await loadFonts(fontSheets)
  document.body.style.fontFamily = baseFamily
}

/**
 * Загружаем темы кастомизации
 * @param {object} options
 * @param {string} options.theme - название темы, соовтетствует названию проекта
 * @param {string} options.colorFromEmbedCode - цвет проекта, из кода вставки виджета
 * @param {string} options.themeMode - темная/светлая тема
 *
 * @returns {Promise<object>} result - с двумя полями result.ui и result.fontSheets
 */
export async function loadAndCreateTheme({
  theme = 'default',
  colorFromEmbedCode,
  themeMode = 'light'
}) {
  let result

  try {
    const {
      ui,
      fontSheets,
      fieldVariation,
      themeMode: loadedThemeMode
    } = await loadTheme({
      project: theme,
      merge: (theme) => {
        return deepmerge.all([
          theme,
          theme.ui,
          theme.quiz || {},
          ...(themeMode === 'dark' && !!theme.dark
            ? [theme.dark, theme.dark.quiz || {}, {themeMode: 'dark'}]
            : [])
        ])
      },
      cacheKey: process.env.VERSION
    })

    result = {
      ui: {
        ...ui,
        fieldVariation,
        themeMode: loadedThemeMode
      },
      fontSheets
    }
  } catch (e) {
    console.log(e)
    defaultUi.colors.primary = colorFromEmbedCode
    result = {
      ui: defaultUi,
      fontSheets: {Roboto: 'https://static.rambler.ru/fonts/Roboto/Roboto.css'},
      errorCode: e.code
    }
  }

  colorFromEmbedCode ||= result.ui.colors.primary

  result.ui.colors.primary = colorFromEmbedCode
  result.ui.colors.primaryDark = darken(colorFromEmbedCode, 0.1)
  result.ui.colors.primaryDarken = darken(colorFromEmbedCode, 0.2)
  result.ui.colors.primaryLight = lighten(colorFromEmbedCode, 0.1)
  result.ui.colors.primaryLighten = lighten(colorFromEmbedCode, 0.2)

  return createRuiTheme(
    deepmerge.all([
      {colors},
      result.ui,
      {fontSheets: result.fontSheets, errorCode: result.errorCode}
    ])
  )
}
