/**
 * Точка входа в приложение
 */
import 'core-js/es/promise'
import 'core-js/modules/es.object.assign'
import createMediator from './mediator'

if (
  !window[process.env.WIDGETLY_NAMESPACE] ||
  Array.isArray(window[process.env.WIDGETLY_NAMESPACE])
) {
  const queue = window[process.env.WIDGETLY_NAMESPACE] || []
  const mediator = createMediator().externalize()

  window[process.env.WIDGETLY_NAMESPACE] = mediator
  window[process.env.WIDGETLY_NAMESPACE].push = (callback) => callback(mediator)
  queue.forEach((method) => mediator.push(method))
}
