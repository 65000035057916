import css from './index.css'

export default ({color, borderRadius, type}) => `
<div class="${css.Spinner} ${css[type]}" style="border-radius: ${borderRadius}px;">
  <div class="${css.Spinner__container}">
    <div class="${css.Spinner__circle}">
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
      <circle cx="12.5" cy="12.5" r="10" stroke-width="5" fill="transparent" shape-rendering="geometricPrecision" stroke="${color}" stroke-dasharray="62.8319" stroke-dashoffset="0" stroke-linecap="round" stroke-opacity="0.3">
      </circle>
      <circle cx="12.5" cy="12.5" r="10" stroke-width="5" fill="transparent" shape-rendering="geometricPrecision" stroke-dasharray="62.8319" stroke-dashoffset="31.4159" stroke="${color}" stroke-linecap="round">
        <animateTransform attributeName="transform" attributeType="xml" type="rotate" from="0 12.5 12.5" to="360 12.5 12.5" dur="1.5s" additive="sum" repeatCount="indefinite" />
        <animate attributeName="stroke-dashoffset" dur="1.2s" values="31.4159;18.8496;31.4159" repeatCount="indefinite" direction="alternate" />
      </circle>
    </svg>
    </div>
  </div>
</div>`
