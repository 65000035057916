export default {
  // Базовые цвета темы
  colors: {
    primary: '#315efb'
  },
  // Настройки для кнопок
  button: {
    sizes: {
      medium: {
        fontSize: 11
      },
      small: {
        fontSize: 11
      }
    }
  },
  // Настройки для типографики
  typography: {
    text: {
      fontFamily: 'Roboto, sans-serif',
      fontSize: 13,
      lineHeight: 20,
      fontWeight: 400,
      fontStyle: 'normal',
      letterSpacing: 0
    },
    h1: {
      fontFamily: 'Roboto, sans-serif',
      fontSize: 30,
      lineHeight: 35,
      fontWeight: 500,
      fontStyle: 'normal',
      letterSpacing: 0
    },
    h2: {
      fontFamily: 'Roboto, sans-serif',
      fontSize: 22,
      lineHeight: 25,
      fontWeight: 500,
      fontStyle: 'normal',
      letterSpacing: 0
    },
    h3: {
      fontFamily: 'Roboto, sans-serif',
      fontSize: 13,
      lineHeight: 20,
      fontWeight: 500,
      fontStyle: 'normal',
      letterSpacing: 0
    }
  }
}
